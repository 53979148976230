import React, { useEffect, useRef, useState } from 'react';
import { broadcaster, useBroadcastedState } from 'base/utils/events';
import { getCartTotal, canModifyOrder } from '../../../helpers/StoreHelper';
import axios from 'axios';
import { Popup } from 'base/ui/popups';
import { GenericException } from 'base/ui/errors';
import SelectDeliveryPoint from './SelectDeliveryPoint';
import SelectDeliveryTime from './SelectDeliveryTime';
import SelectAddressList from './SelectAddressList';
import { EmptyView, LoadingOverlay, NotFoundView } from 'base/ui/status';
import CatalogProduct from './product/CatalogProduct';
import DiscountView from './DiscountView';
import { CountDownView, DateView } from 'base/ui/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFileInvoice, faPencil, faTrashAlt, faUserAlt, faX } from '@fortawesome/free-solid-svg-icons';
import  {ReactComponent as PointsIcon} from '../../../images/ic_points.svg';
import MapMarker from "../../../images/map_marker.svg"
import ApplyDiscountAnim from "../../../images/apply_discount.gif"
import DeliveryVan from "../../../images/delivery_van.svg"
import CartProduct from './product/CartProduct';
import { getDisplayPrice } from '../../../helpers/StoreHelper';
import SelectProducts from '../shared/SelectProducts';
import { CUSTOM_DISCOUNT_CODE, ORDER_STATUS_DELIVERED, ORG_FEATURE_FLAGS } from '../../../constants';
import { cache } from '../../../user';
import ProductSubSelections from './product/ProductSubSelections';
import OrderCta from '../deliveryRoute/orders/OrderCta';
import  {ReactComponent as CheckMark} from '../../../images/checkmark.svg';
import EventTracker from '../../../eventsTracker';

function getManualDiscount(cart) {
    let manual_discount;
    Object.entries(cart?.pricing?.discounts || {}).map(
        ([discount_code, data]) => {
            if (data.manual_discount) {
                manual_discount = data;
            }
        }
    );
    return manual_discount
}
function validateAppliedCoupon(code, pricing) {
    if (pricing.discount_prompts[code.toUpperCase()]) {
        Popup.show("Error", <div className='tw-p-4'>{
            pricing.discount_prompts[code.toUpperCase()].description
        }</div>)
    }
}

/**
 * 
 * @param {order} order - active order if any 
 * @param {buying_for_user} buying_for_user - if buying for user mode
 * @param {cart} cart - mandatory
 * @param {delivery_route} delivery_route - mandatory
//  * @param {user} user - mandatory
 * @param {onDeliveryPointSelect} onDeliveryPointSelect - skip if delivery_point and delivery_time selections are not handled in parent component.
 * @param {onDeliveryTimeSelect} onDeliveryTimeSelect - skip if delivery_point and delivery_time selections are not handled in parent component.
 * @param {onViewProduct} onViewProduct - handler on clicking a product, skip if product details need not be opened from cart
 * @param {onRefresh} onRefresh - handler on deletion of active order.
 * @param {onOrderUpdate} onOrderUpdate - handler on update to an active order.
 * @param {is_from_store} is_from_store - default is false. If true, products/discounts can be added from cart.
 * @param {is_from_bfc} is_from_bfc - default is false. If true, discounts can be added from chat bfc.
 * @returns Review Cart View
 */
function CartAndOrderView({
    order: _order, cart:_cart, delivery_route,
    user, buying_for_user,
    onDeliveryPointSelect, onDeliveryTimeSelect,
    onViewProduct, 
    onRefresh,
    onOrderUpdate,
    is_from_store,
    is_from_bfc,
    container,
    is_deleted_order
}){
    const [cart, setCart] = useBroadcastedState("cart_updated", _cart || {});
    const [order] = useBroadcastedState("order_updated", _order);
    const cart_items = cart?.items || {};
    const order_items = order?.items || {};
    const {total_amount, total_saving, items_total, total_tax_amount, cp_ids, abandoned_cp_ids, total_lp} = getCartTotal(order, cart);
    const [is_deleted, setIsDeleted] = useState(false);
    const view_ref = useRef();
    const [manual_discount, setManualDiscount] = useState(getManualDiscount(cart))
    const [coupon_code, setCouponCode] = useState(manual_discount?.code || "")
    const [play_discount_anim, setPlayDiscountAnim] = useState()
    
    const delivery_time = cart?._id ? cart.delivery_time : order?.delivery_time;
    const delivery_point_name = cart?._id ? cart.delivery_point_name : order?.delivery_point_name;
    const delivery_point = delivery_route?.delivery_points.find(
        (dpoint) => dpoint.name === delivery_point_name
    ) || {"name": delivery_point_name};

    const home_delivery_address = cart?._id ? cart.address : order?.address;
    const home_delivery_enabled = (user?._id || buying_for_user?._id) && cart?.pricing?.delivery_info?.home_delivery_enabled;

    const can_modify = canModifyOrder(order) && !is_deleted_order;
    const [is_editing_order, setIsEditingOrder] = useState(order?._id && can_modify && cart?._id);

    const [addresses, setAddresses] = useState(false);
    const currency = order?.currency || cart?.pricing?.currency || delivery_route?.currency;
    const delivery_instructions = cart?.instructions?.delivery || order?.instructions?.delivery;
    const [checkout_settings, setCheckoutSettings] = useState({
        auto_confirm_checkout: false, no_cancellation: false, do_not_notify: false
    });
    const ctx = useRef({custom_discount: {}, delivery_time_ranges: null}).current;
    const [is_loading, setLoading] = useState(false);

    const is_user_manager_or_delivery_staff = (
        user?.roles?.superadmin ||
        user?.roles?.staff ||
        user?.roles?.['delivery_org_manager'] ||
        user?.roles?.['manager'] ||
        user?.roles?.['delivery']
    )

    useEffect(() => {
        setIsEditingOrder(order?._id && can_modify && cart?._id);
    }, [cart?._id]);

    useEffect(() => {
        setCouponCode(manual_discount?.code || "")
    }, [manual_discount]);

    /* To fetch delivery time ranges for an order based on delivery point */
    useEffect(() => {
        if (is_editing_order && delivery_point_name && !ctx.delivery_time_ranges) {
            updateCartDelivery();
        }
    }, [delivery_point_name, is_editing_order])

    const deleteOrder = function(evt) {
        let reason = window.prompt("Are you sure. Can you tell us the reason (optional) ?")
        if(reason === null){
            return
        }
        axios.post(
            `/api/edit/order/${order.delivery_route_id}/${order._id}`,
            {"action": "delete", "reason": reason}
        )
        .then((resp) => {
            if(resp.data.errors){
                Popup.show(
                    "Unable to delete Order",
                    <GenericException ex={resp.data.errors} />
                );
                return;
            }
            if(resp.data.deleted){
                setIsDeleted(true);
                setCart({});
                cache.set([delivery_route._id, buying_for_user?._id, "cart_id"], null)
                onRefresh && onRefresh(null);
            }
        });
    }

    const updateOrder = (_order) => {
        if (onOrderUpdate) {
            onOrderUpdate(_order);
        }
        if (_order.status === ORDER_STATUS_DELIVERED) {
            onRefresh();
        }
        broadcaster.broadcast_event("order_updated", _order)
    }

    const doUnfreeze = () => {
        if(ctx.is_loading) return;
        ctx.is_loading = true;
        axios.post(
            `/api/edit/order/${order.delivery_route_id}/${order._id}`, 
            {action: 'admin_unfreeze'}
        ).then((resp) => {
            if(resp.data.errors){
                Popup.show("Error Occurred!", <GenericException ex={resp.data.errors} />)
                return;
            }
            updateOrder(resp.data.order);
        }).finally(() => {ctx.is_loading = false});
        
    }

    /* change delivery point on the order */
    const changeOrderDeliveryPoint = function(){
        if(!order?._id) return;
        var popup = Popup.slideUp(
            <SelectDeliveryPoint delivery_route={delivery_route}
                selected_delivery_point={delivery_point}
                onDeliveryPointSelected={
                    (dpoint) => {
                        if(!dpoint) return;
                        popup.close();
                        updateCartDelivery({delivery_point_name: dpoint.name, 
                            address: ''
                        })
                    }
                }
            />,
            {"title": "Select Delivery Location", container}
        );
    }

    /* change delivery time on the order */
    const changeOrderDeliveryTime = function(){
        if(!order?._id) return;
        const selected_delivery_time = delivery_time;
        var popup = Popup.slideUp(
            <SelectDeliveryTime delivery_route={delivery_route}
                delivery_time_ranges={ctx.delivery_time_ranges}
                selected_delivery_time_range={ctx.delivery_time_ranges?.find(
                    (dtime) => selected_delivery_time === dtime[0]
                )}
                onDeliveryTimeRangeSelected={
                    (time_range) => {
                        if(!time_range) return;
                        popup.close();
                        updateCartDelivery({delivery_time: time_range[0]})
                    }
                }
            />,
            {"title": "Select Delivery Time", container}
        );
    }

    /* change delivery address on the order */
    const changeOrderDeliveryAddress = function() {
        var popup = Popup.slideUp(
            <SelectAddressList delivery_route={delivery_route}
                selected_address={home_delivery_address}
                user={user}
                buying_for_user={buying_for_user}
                delivery_route_id={delivery_route._id}
                addresses={addresses}
                setAddresses={setAddresses}
                onAddressSelected={
                    (address) => {
                        popup.close();
                        updateCartDelivery({address})
                    }
                }
            />,
            {"title": "Select Address", container}
        );
    }

    const updateCartDelivery = (payload) => {
        if (is_loading) return;
        setLoading(true);
        axios.post(
            `/api/cart/diff/${delivery_route._id}`,
            {
                cart_id: cart._id,
                existing_order_id: order?._id,
                buying_for_user_id: buying_for_user?._id,
                delivery_point_name: cart.delivery_point_name || order?.delivery_point_name,
                delivery_time: cart.delivery_time || order?.delivery_time,
                address: cart?.address || order?.address,
                ...payload
            }
        ).then(
            (resp) => {
                if(resp.data.errors){
                    Popup.show("Error Occurred!", <GenericException ex={resp.data.errors} />)
                }
                /* main order updated */
                resp.data.order 
                    && broadcaster.broadcast_event("order_updated", resp.data.order);

                let _cart = resp.data.cart || {...cart};
                if(resp.data.updated_items){
                    Object.assign(_cart.items, resp.data.updated_items);
                }
                if(resp.data.pricing){
                    _cart.pricing = resp.data.pricing;
                }
                if(resp.data.delivery_time_ranges) {
                    ctx.delivery_time_ranges = resp.data.delivery_time_ranges;
                }
                broadcaster.broadcast_event("cart_updated", _cart);
            }
        ).finally(() => setLoading(false));
    }

    const openInstructions = () => {
        ctx.instructions = delivery_instructions;
        var popup = Popup.slideUp( 
            <div className='tw-p-4'>
                <div>
                    <textarea className='tw-w-full tw-border tw-mb-4 tw-px-4 tw-py-2 tw-text-sm' 
                        placeholder={`Enter instructions for your order delivery`} rows={2} defaultValue={delivery_instructions} 
                        onChange={(evt) => ctx.instructions = evt.target.value} 
                    />
                </div>
                <button className='tw-px-4 tw-py-2 tw-bg-secondary-lighter tw-text-white tw-text-sm'
                    onClick={() => {popup.close(); addInstructions()}}
                >Add Note</button>
            </div>,
            {title: '', container}
        )
    }

    const addInstructions = () => {
        updateCartDelivery({instructions: {'delivery': ctx.instructions},
            return_full_cart: true
        });
    }


    const setCartCheckoutSetting = (key) => {
        return (evt) => {
            checkout_settings[key] = evt.target.checked;
            if (key === 'auto_confirm_checkout' && !evt.target.checked) {
                checkout_settings.do_not_notify = false;
            }
            setCheckoutSettings({...checkout_settings});
        }
    }

    const handleCheckout = () => {
        if (order?._id && !cart.delivery_point_name) {
            changeOrderDeliveryPoint();
            return;
        } 
        if (order?._id && !cart.delivery_time) {
            changeOrderDeliveryTime();
            return;
        } 
        if (!cart.delivery_point_name && (!buying_for_user?._id || checkout_settings.auto_confirm_checkout)) {
            onDeliveryPointSelect();
            return;
        }
        if (!cart.delivery_time && (!buying_for_user?._id || checkout_settings.auto_confirm_checkout)) {
            onDeliveryTimeSelect();
            return;
        }
        if (home_delivery_enabled && !cart.address && !cart?.pricing?.delivery_info?.dpoint_delivery_enabled && (!buying_for_user?._id || checkout_settings.auto_confirm_checkout)) {
            changeOrderDeliveryAddress();
            return;
        }
        Object.keys(checkout_settings).forEach(key => {
            if (!checkout_settings[key]) delete checkout_settings[key]
        });
        EventTracker.track("CustomerSupport_BuyForCustomer");
        cart.settings = checkout_settings;
        cart?.doCheckout()
    }

    const addProduct = ({products}) => {
        if (products?.length) {
            const cp = products[0];
            if (!cp.qty || cp.qty < 0) {
                Popup.toast(
                    <div className='tw-rounded tw-p-2 tw-text-sm'>Item selected is out of stock.</div>,
                    2500
                )
                return;
            }
            if (cp.sub_selections?.length) {
                const configs = {...(order_items?.[cp._id]?.configs || {}), ...(cart_items?.[cp._id]?.configs || {})}
                var popup = Popup.slideUp(
                    <ProductSubSelections 
                        delivery_route={delivery_route} 
                        sub_selections={cp.sub_selections} 
                        onAdd={(config) => {
                            config.qty = 1;
                            updateCartDelivery({
                                catalog_product_id: cp._id,
                                diff_qty: 1,
                                config, config_index: Object.keys(configs || {}).length + 1 
                            }); 
                            popup.close(); 
                        }}
                    />,
                    {container}
                )
                return;
            }
            updateCartDelivery({
                catalog_product_id: products[0]._id,
                diff_qty: 1,
            })
        }
    }

    const AddProduct = () => (
        !is_from_store
            ?   <div className='tw-p-4 tw-text-center tw-border-t tw-border-dashed'>
                    <button className='tw-text-sm tw-font-bold tw-border tw-w-full md:tw-max-w-[50%] tw-text-secondary-lighter tw-rounded tw-px-4 tw-py-2 tw-border-secondary-lighter'
                        onClick={openAddProduct}
                    >
                        + Add Product
                    </button>
                </div>
            :   null
    )

    const saveDeliveryFee = () => {
        updateCartDelivery({
            home_delivery_fee: `${ctx.home_delivery_fee}`
        })
    }
    const saveCustomDiscount = () => {
        updateCartDelivery({
            custom_discount: ctx.custom_discount
        })
    }

    const deleteCustomDiscount = () => {
        updateCartDelivery({
            custom_discount: {}
        })
    }

    const openCustomDiscount = () => {
        var popup = Popup.slideUp((
            <div className='tw-pt-4 tw-pb-10'>
                <div className='tw-px-4'>
                    <div className='tw-mb-4'>Set Custom Discount</div>
                    <input type='text' className='w3-input tw-text-sm tw-outline-0 tw-mb-4' defaultValue={ctx.custom_discount.description}
                        onChange={(evt) => ctx.custom_discount.description = evt.target.value}
                        placeholder='Discount Title'
                    />
                    <input type='number' className='w3-input tw-text-sm tw-outline-0 tw-mb-4' defaultValue={ctx.custom_discount.amount ? ctx.custom_discount.amount / 1000 : null}
                        onChange={(evt) => ctx.custom_discount.amount = parseInt(evt.target.value)}
                        placeholder='Amount'
                    />
                </div>
                <div className='tw-flex tw-gap-4 tw-absolute tw-bottom-0 tw-w-full tw-p-4'>
                    {ctx.custom_discount?.amount
                    ?   <button className='tw-border tw-rounded tw-px-4 tw-py-2 tw-text-gray-600 tw-shrink-0' onClick={() => {deleteCustomDiscount(); popup.close()}}>
                            Remove Discount
                        </button>
                    :   null
                    }
                    <button className='tw-border tw-border-secondary-bg tw-bg-secondary-lighter tw-rounded tw-grow tw-px-4 tw-py-2 tw-text-white' onClick={() => {saveCustomDiscount(); popup.close()}}>
                        Save
                    </button>
                </div>
                
            </div>
        ), {container})
    }

    const openDeliveryFee = () => {
        var popup = Popup.slideUp((
            <div className='tw-pt-4 tw-pb-10'>
                <div className='tw-px-4'>
                    <div className='tw-mb-4'>Set Delivery Fee</div>
                    <input type='number' className='w3-input tw-text-sm tw-outline-0 tw-mb-4' defaultValue={ctx.home_delivery_fee ? ctx.home_delivery_fee / 1000 : null}
                        onChange={(evt) => ctx.home_delivery_fee = parseInt(evt.target.value)}
                        placeholder='Amount'
                    />
                </div>
                <div className='tw-flex tw-gap-4 tw-absolute tw-bottom-0 tw-w-full tw-p-4'>
                    <button className='tw-border tw-border-secondary-bg tw-bg-secondary-lighter tw-rounded tw-grow tw-px-4 tw-py-2 tw-text-white' onClick={() => {saveDeliveryFee(); popup.close()}}>
                        Save
                    </button>
                </div>
                
            </div>
        ), {container})
    }

    const openAddProduct = () => {
        var popup = Popup.slideUp((
            <div className='tw-p-4'>
                <div className='tw-mb-4'>Add Product</div>
                <SelectProducts 
                    max_selections={1} 
                    delivery_route={delivery_route} 
                    onSelect={(data) => {addProduct(data); popup.close();}} 
                    input_className={'tw-border tw-px-4 tw-py-2 tw-rounded tw-w-full tw-border-gray-300 tw-outline-0'}   
                />
            </div>
        ), {container})
    }

    const downloadInvoice = () => {
        if (is_loading) return
        setLoading(true);
        axios.get(`/api/admin/delivery_route/${delivery_route._id}/order/${order._id}/invoice`)
            .then((resp) => {
                if(resp.data.errors){
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                    return;
                }
                var link = document.createElement("a");
                link.target = '_blank';
                link.download = `Invoice_${order._id}`;
                link.href = resp.data.invoice_file_url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {Popup.show("Errors", <GenericException ex={err} />)})
            .finally(() => setLoading(false));
    }
    const updateCoupon = (action, code) => {
        if (is_loading) return
        setLoading(true);
        axios.post(`/api/delivery_route/${delivery_route._id}/discounts?cart_id=${cart._id}&action=${action}&code=${code}`)
            .then((resp) => {
                if (resp.data.errors) {
                    Popup.show("Errors", <GenericException ex={resp.data.errors} />);
                    return;
                }
                cart.pricing = resp.data.pricing
                document.getElementById("input_apply_discount").value = ""
                const applied_discount = getManualDiscount(cart);
                if (action === "apply") {
                    if (applied_discount) {
                        setPlayDiscountAnim(true)
                        setTimeout(() => {
                            setPlayDiscountAnim(false)
                        }, 1000)
                    } else {
                        validateAppliedCoupon(code, resp.data.pricing)
                    }
                }
                setManualDiscount(applied_discount)
            })
            .catch((err) => { Popup.show("Errors", <GenericException ex={err} />) })
            .finally(() => setLoading(false));
    }

    if(is_deleted){
        return <EmptyView title="Order deleted" height="100px" />
    }
    if(!cp_ids?.length){
        return (
        <>
            <NotFoundView image={'https://storage.googleapis.com/sukhiba/mobilefresh/user_uploads/2024-02-02/1706889389Qivh2BmKSo__cartempty.png'} 
                title={`${buying_for_user?._id ? buying_for_user.name + "'s" : 'Your'} Cart is Empty!`} height="100px" 
            />
            <AddProduct />
        </>
        )
    }

    const additional_costs = cart?.pricing?.additional_costs || order?.additional_costs;
    const discounts = cart?.pricing?.discounts || order?.discounts;
    ctx.custom_discount = discounts?.[CUSTOM_DISCOUNT_CODE] || {};
    ctx.home_delivery_fee = additional_costs?.home_delivery_fee?.amount;
    /* Non product specific discount prompts */
    const general_discounts = Object.entries(cart?.pricing?.discount_prompts || {})?.filter(([discount_code, data]) => !data.specific_product_id);

    return (
        <div ref={view_ref} className='tw-pb-48'>
            {is_loading ? <LoadingOverlay /> : null}
            <div className={!container ? 'md:tw-px-4' : ''}>
                {
                    order?._id
                    ?   <div className='hflex tw-px-4 tw-gap-4 tw-bg-gray-bg tw-mb-2'>
                            <div className='tw-text-sm tw-font-bold tw-my-2'>
                                #{order._id}
                                {order.purchased_by_user 
                                    ?   <div className='tw-text-xs tw-font-normal tw-mt-2'>
                                            <FontAwesomeIcon icon={faUserAlt} />&nbsp;&nbsp;
                                            {buying_for_user?.name}&apos;s Order (Purchased by {order.purchased_by_user.name})
                                        </div> 
                                    :   null
                                }
                            </div>
                            {
                                !can_modify 
                                ?   is_user_manager_or_delivery_staff 
                                    ?   order?.status === ORDER_STATUS_DELIVERED
                                        && delivery_route?.delivery_org?.feature_flags?.[ORG_FEATURE_FLAGS.DOWNLOAD_ORDER_INVOICE]?.is_active
                                        ?   <button className='tw-px-3 tw-py-1 tw-text-xs tw-text-primary tw-bg-white tw-border tw-border-primary tw-rounded tw-my-1.5'
                                                onClick={downloadInvoice}
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} className='tw-pr-2' />
                                                View Invoice
                                            </button>
                                        :   !is_deleted_order ? <div onClick={() => doUnfreeze()}>Click to unfreeze(2min)</div> : null
                                    :   null
                                :  is_editing_order
                                    ?   <FontAwesomeIcon icon={faTrashAlt} className='tw-text-red-800 tw-pr-4 tw-py-2' onClick={deleteOrder} />
                                    :   <button className='tw-px-3 tw-py-1 tw-text-xs tw-font-bold tw-text-secondary-lighter tw-bg-white tw-border tw-border-secondary-lighter tw-rounded tw-my-1.5' onClick={() => setIsEditingOrder(true)}>
                                            Edit Order {
                                                order && <CountDownView 
                                                    timestamp={order.can_modify_until} onEnd={() => setIsEditingOrder(false)}
                                                />
                                            }
                                        </button>
                            }
                        </div>
                    :   null
                }
                <div>
                    {
                        cp_ids.map(
                            (cp_id, i) => {
                                let item = cart_items[cp_id] || order_items[cp_id];
                                let configs = {...(order_items[cp_id]?.configs || {}), ...(cart_items[cp_id]?.configs || {})}
                                let sub_products = item.catalog_product.sub_selections?.length 
                                    ? Object.entries(configs || {}).filter(([config_index, config]) => !!config?.sub_selections) 
                                    : [[]] // keeping empty array to render parent product by default
                                return  sub_products.map(([config_index], j) => (
                                    <CartProduct
                                        className={i < cp_ids.length - 1 || j < sub_products.length - 1 ? `tw-border-b tw-border-dashed ${i}` : ''}
                                        delivery_route={delivery_route}
                                        catalog_product={item.catalog_product}
                                        price={item.unit_price}
                                        qty={item.qty}
                                        key={cp_id + config_index + cart?._id} /* cart id in key is to refresh products when new cart gets created after order */
                                        order={order}
                                        buying_for_user={buying_for_user}
                                        cart={cart}
                                        can_modify={can_modify}
                                        updateCart={updateCartDelivery}
                                        onViewProduct={() => onViewProduct(item.catalog_product)}
                                        configs={configs}
                                        config_index={config_index}
                                    />
                                ))
                            }
                        )
                    }
                    {can_modify ? <AddProduct /> : null}
                </div>
                {
                    general_discounts?.length > 0
                    ?   <div className='tw-border-t-8 tw-border-gray-bg tw-p-4'>
                            <div className='tw-font-bold tw-mb-4'>Offers Available ({general_discounts.length})</div>
                            {
                                general_discounts.map(
                                    ([discount_code, data]) => (
                                        <div key={discount_code} className='tw-mb-2'>
                                            <DiscountView data={data} currency={currency} />
                                        </div>
                                    ))
                            }
                        </div>
                    :   null
                }
                {
                    cart?._id || order?._id
                    ?   <>
                            <div className='tw-border-y-8 tw-border-gray-bg tw-p-4 tw-relative'>
                                {/* Apply Coupon on cart */}
                                {
                                    is_from_store || is_from_bfc
                                        ? <div className='tw-mb-4 '>
                                            <div className='tw-font-bold tw-mb-2'>Apply Coupon
                                                {
                                                    manual_discount
                                                        ? <div className='tw-font-bold tw-flex tw-gap-1 tw-items-center tw-text-xs tw-text-green-800 tw-float-right'>
                                                            <CheckMark style={{ width: 14, height: 14 }} fill={"green"} />
                                                            Applied
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    play_discount_anim ? <img src={ApplyDiscountAnim} className='tw-z-20 tw-absolute tw--top-10 tw-right-10 ' /> : null
                                                }
                                            </div>
                                            <div className='tw-flex tw-items-center tw-justify-between tw-border tw-px-2 tw-py-1 tw-text-xs tw-rounded tw-leading-5'>
                                                <input id='input_apply_discount' className='tw-outline-0 tw-mr-2 tw-py-2 tw-w-full'
                                                    value={coupon_code || ""}
                                                    disabled={manual_discount || false}
                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                />
                                                <div>
                                                    {
                                                        manual_discount
                                                            ? <FontAwesomeIcon icon={faX} onClick={() => {
                                                                updateCoupon("remove", coupon_code) 
                                                            }} />
                                                            : <button className={`tw-font-bold ${coupon_code?.length > 0 ? 'tw-text-secondary' : 'tw-text-[#999999]'}`}
                                                                onClick={() => {
                                                                 
                                                                    coupon_code?.length > 0 && updateCoupon("apply", coupon_code)
                                                                }}>APPLY
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className='tw-font-bold tw-mb-4'>Payment Summary</div>
                                <div className='tw-relative'>
                                    {
                                        items_total && Math.abs(total_amount - items_total) > 100
                                        ?   <div className='hflex tw-mb-2'>
                                                <div className='tw-text-sm tw-text-gray-500'>Items Total</div>
                                                <div className='tw-text-sm tw-text-gray-600'>
                                                    {getDisplayPrice(currency, items_total)}
                                                </div>
                                            </div>
                                        :   null
                                    }
                                    {total_lp > 0
                                        ? <div className='hflex tw-mb-2'>
                                            <div className='tw-text-sm tw-text-gray-500'>Total Points Earned</div>
                                            <div className='tw-text-sm tw-text-gray-600 tw-flex tw-items-center tw-gap-1'>
                                                <PointsIcon style={{ width: 12, height: 12, }} />
                                                {total_lp + " points"}
                                            </div>
                                        </div>
                                        : null
                                    }
                                    {
                                        Object.entries(additional_costs || {}).map(
                                            ([type, data]) => (
                                                <div className='hflex tw-mb-2' key={type}>
                                                    <div className='tw-capitalize tw-text-sm tw-text-gray-500'>{data.title || type.split('_').join(' ')}</div>
                                                    <div className='tw-text-sm tw-text-gray-600'>
                                                        {getDisplayPrice(currency, data.amount)}
                                                    </div>
                                                </div>
                                        ))
                                    }
                                    {
                                        Object.entries(discounts || {}).map(
                                            ([discount_code, data]) => (
                                                <div key={discount_code} onClick={discount_code === CUSTOM_DISCOUNT_CODE ? openCustomDiscount : null}>
                                                    <DiscountView data={data} is_from_store={is_from_store || is_from_bfc} currency={currency} onRemove={(code) => updateCoupon("remove", code)} />
                                                </div>
                                            ))
                                    }                                    
                                </div>
                                <div className='tw-border-t tw-border-gray-300 tw-border-dashed tw-pt-3'>
                                    <div className='hflex tw-mb-2'>
                                        <div className='tw-text-sm tw-font-bold'>Total(incl tax)</div>
                                        <div className='tw-text-md tw-font-bold'>
                                            {getDisplayPrice(currency, total_amount)}
                                        </div>
                                    </div>
                                    {
                                        total_tax_amount && total_tax_amount > 100
                                        ?   <div className='hflex'>
                                                <div className='tw-text-sm'>Tax</div>
                                                <div className='tw-text-sm tw-font-bold'>
                                                    {getDisplayPrice(currency, total_tax_amount)}
                                                </div>
                                            </div>
                                        :   null
                                    }
                                    {
                                        total_saving && total_saving > 100
                                        ?   <div className='hflex'>
                                                <div className='tw-text-sm'>Saved</div>
                                                <div className='tw-text-sm tw-font-bold tw-text-green-600'>
                                                    {getDisplayPrice(currency, total_saving)}
                                                </div>
                                            </div>
                                        :   null
                                    }
                                </div>
                                {
                                    buying_for_user?._id && can_modify
                                    ?   <div className='tw-mt-6 tw-flex tw-gap-4'>
                                            {delivery_route?.max_bfc_discount_percentage > 0 ? <button className='tw-text-sm tw-px-2 tw-py-1 tw-border tw-border-gray-300 tw-rounded tw-flex-1' onClick={openCustomDiscount}>+ Custom Discount</button> : null}
                                            <button className={`tw-text-sm tw-px-2 tw-py-1 tw-border tw-border-gray-300 tw-rounded ${delivery_route?.max_bfc_discount_percentage > 0 ? 'tw-flex-1' : 'tw-w-fit'}`} onClick={openDeliveryFee}>+ Set Delivery Fee</button>
                                        </div>
                                    :   null
                                }
                            </div>
                            <div className='tw-p-4 tw-border-b-8 tw-border-gray-bg'>
                                <div className='hflex'>
                                    <div className='tw-font-bold tw-mb-4'>Delivery</div>
                                </div>
                                {
                                    delivery_point.delivery_times && delivery_time
                                    ?   <div className='tw-flex tw-items-center tw-gap-4 tw-mb-2 tw-text-sm tw-cursor-pointer'
                                            onClick={!can_modify ? null : order?._id ? changeOrderDeliveryTime : onDeliveryTimeSelect}
                                        >
                                            <div className='tw-bg-primary-bg tw-rounded-lg tw-p-2'><img src={DeliveryVan} alt="location" className='tw-w-5'/></div>
                                            <span><DateView millis={delivery_time} /></span>
                                            {can_modify ?<FontAwesomeIcon className='tw-ml-auto tw-text-gray-600' icon={faChevronRight} /> : null}
                                        </div>
                                    :   null
                                }
                                <div className='tw-flex tw-items-center tw-gap-4 tw-text-sm' onClick={!can_modify ? null : order?._id ? changeOrderDeliveryPoint : onDeliveryPointSelect}>
                                    <div className='tw-bg-primary-bg tw-rounded-lg tw-p-2'><img src={MapMarker} alt="location" className='tw-w-5'/></div>
                                    <span>{delivery_point_name || 'Select Delivery Location'}</span>
                                    {can_modify ? <FontAwesomeIcon className='tw-ml-auto tw-text-gray-600' icon={faChevronRight} /> : null}
                                </div>
                                {
                                    cart?.delivery_point_name && !cart?.pricing?.delivery_info?.dpoint_delivery_available && !cart?.pricing?.delivery_info?.home_delivery_available
                                    ?   <div className='tw-px-4 tw-mt-2 tw-text-red-700 tw-text-xs'>Delivery to this point is currently not available.</div>
                                    :   null
                                }
                                {
                                    (!can_modify && home_delivery_address) || home_delivery_enabled 
                                    ?   <div className='tw-bg-primary-fade tw-px-4 tw-py-2 hflex tw-gap-4 tw-text-xs tw-mt-4' onClick={can_modify ? changeOrderDeliveryAddress : null}>
                                            {
                                                home_delivery_address
                                                ?   <span><b>HOME DELIVERY-</b> {home_delivery_address}</span>
                                                :   <span>
                                                        {cart?.pricing?.delivery_info?.home_delivery_fee === 0 ? <span>Free&nbsp;</span> : null}
                                                        Home Delivery available from {delivery_point_name}
                                                        &nbsp;{cart?.pricing?.delivery_info?.home_delivery_fee > 0 ? `for ${getDisplayPrice(currency, cart?.pricing?.delivery_info?.home_delivery_fee)}` : ''}
                                                    </span>
                                            }
                                            {
                                                can_modify
                                                ?   <span className='tw-text-secondary-lighter tw-tracking-wide tw-text-xs tw-ml-auto tw-shrink-0'>{home_delivery_address ? 'CHANGE' : 'SELECT'} ADDRESS</span> 
                                                :   null
                                            }
                                        </div>
                                    :   null
                                }
                                {can_modify || delivery_instructions
                                ?   <div className='tw-mt-4'>
                                        <div className='tw-border tw-px-2 tw-py-1 tw-text-xs tw-rounded tw-w-fit tw-leading-5' onClick={() => {can_modify ? openInstructions() : null}}>
                                            {can_modify ? <FontAwesomeIcon icon={faPencil} className='tw-mr-2' /> : null}
                                            {delivery_instructions 
                                                ?   <><span className='tw-text-gray-500'>Delivery Instructions: </span> {delivery_instructions}</> 
                                                :   <span>Add Delivery Instructions (if any)</span>
                                            }
                                        </div>
                                    </div>
                                :   null
                                }
                            </div>
                        </>
                    :   null
                }
                
                {
                    is_from_store && abandoned_cp_ids?.length && can_modify
                    ?   <div className='tw-my-4 tw-p-4 tw-bg-gray-bg'>
                            <div className='tw-font-bold tw-mb-4 tw-text-sm'>You may add these back to the cart!</div>
                            <div className='tw-flex tw-w-fit tw-overflow-x-auto tw-border' style={{maxWidth: '100%'}}>
                                {
                                    abandoned_cp_ids.map(
                                        (cp_id) => {
                                            let cart_item = cart_items[cp_id];
                                            return <CatalogProduct
                                                className={'tw-outline tw-outline-1 tw-outline-gray-200 tw-shrink-0'}
                                                delivery_route={delivery_route}
                                                catalog_product={cart_item?.catalog_product}
                                                key={cp_id}
                                                buying_for_user={buying_for_user}
                                                cart={cart}
                                                image_height={100}
                                                grid={true}
                                                onViewProduct={(_cp) => onViewProduct(_cp)}
                                            />
                                        }
                                    )
                                }
                            </div>
                        </div>
                    :   null
                }
            </div>
            {
                cart?._id && (!order?._id || (can_modify && order.status !== ORDER_STATUS_DELIVERED))
                ?   <div className='tw-absolute tw-bottom-0 tw-w-full tw-bg-gray-bg tw-text-center' style={{minHeight: 48}}>
                        <div className='tw-pt-2 tw-text-md'>
                            {
                                cart?.pricing?.delivery_info?.home_delivery_available
                                ?   (cart?.pricing?.delivery_info.need_more_for_home_delivery || 0) > 0
                                    ?   <div>
                                            Add <span className='w3-text-red'>
                                                {getDisplayPrice(currency, cart.pricing.delivery_info.need_more_for_home_delivery)}
                                            </span> more for Home delivery
                                        </div>
                                    :   (cart?.pricing?.delivery_info.need_more_for_free_home_delivery || 0) > 0
                                        ?   <div>
                                                Add <span className='w3-text-red'>
                                                    {getDisplayPrice(currency, cart.pricing.delivery_info.need_more_for_free_home_delivery)}
                                                </span> more for <span className='w3-text-red'>FREE</span> home delivery
                                            </div>
                                        :   null
                                :   null
                            }
                            {
                                cart?.pricing?.delivery_info?.dpoint_delivery_available
                                ?  (cart?.pricing?.delivery_info?.need_more_for_dpoint_delivery || 0) > 0
                                    ?   <div>
                                            Add <span className='w3-text-red'>
                                                {getDisplayPrice(currency, cart.pricing.delivery_info.need_more_for_dpoint_delivery)}
                                            </span> more for delivery
                                        </div>
                                    :   null
                                :   null
                            }
                        </div>
                        {
                            (
                                cart?.pricing?.delivery_info.home_delivery_enabled
                                || cart?.pricing?.delivery_info.dpoint_delivery_enabled
                                || !cart.delivery_point_name  //so it will ask for delivery point
                            )
                            ?   <div className='tw-px-4 tw-py-4'>
                                    {buying_for_user && user 
                                        ?   <div className='hflex tw-items-center tw-flex-wrap tw-gap-3 tw-mb-4 tw--mt-2'>
                                                <label className='tw-flex tw-items-center tw-shrink-0'>
                                                    <input type="checkbox" className='w3-check tw-mr-2' 
                                                        checked={checkout_settings.auto_confirm_checkout}
                                                        onChange={setCartCheckoutSetting('auto_confirm_checkout')}
                                                    />
                                                    <div className='tw-text-sm tw-text-gray-800'>Auto Confirm</div>
                                                </label>
                                                <label className='tw-flex tw-items-center tw-shrink-0'>
                                                    <input type="checkbox" className='w3-check tw-mr-2' 
                                                        checked={checkout_settings.no_cancellation}
                                                        onChange={setCartCheckoutSetting('no_cancellation')}
                                                    />
                                                    <div className='tw-text-sm tw-text-gray-800'>No Cancellation</div>
                                                </label>
                                                {user?.roles?.superadmin || user?.roles?.delivery_org_manager
                                                    ?   <label className='tw-flex tw-items-center tw-shrink-0'>
                                                            <input type="checkbox" className='w3-check tw-mr-2' 
                                                                disabled={!checkout_settings.auto_confirm_checkout}
                                                                checked={checkout_settings.do_not_notify}
                                                                onChange={setCartCheckoutSetting('do_not_notify')}
                                                            />
                                                            <div className='tw-text-sm tw-text-gray-800'>Do Not Notify</div>
                                                        </label>
                                                    :   null
                                                }
                                            </div>
                                        :   null
                                    }
                                    <button className='tw-w-full tw-bg-secondary-lighter tw-text-white tw-px-6 tw-py-2 tw-rounded tw-text-sm tw-flex-1'
                                        onClick={handleCheckout}
                                    >
                                        {order?._id ? "Modify Order" : "Checkout"}
                                    </button>
                                </div>
                            :   null
                        }
                    </div>
                :   is_user_manager_or_delivery_staff && !is_deleted_order
                        ?   <OrderCta order={order} setOrder={updateOrder} delivery_route={delivery_route} />
                        :   null
                
            }
        </div>
    )
}

export default CartAndOrderView