// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc-toolbar-title {
    font-size: 18px !important;
    font-weight: 500;
}
.fc-button-primary {
    font-size: 12px !important;
    text-transform: capitalize !important;
    /* border: 1px solid var(--secondary-color) !important;
    color: var(--secondary-color) !important;
    outline: 0 !important;
    background-color: white !important; */
}
/* .fc-button-primary.fc-button-active {
    background: var(--primary-btn-color) !important;
    color: white !important
} */
.fc-today-button {
    text-transform: capitalize !important;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/csm/css/calendar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;AACA;IACI,0BAA0B;IAC1B,qCAAqC;IACrC;;;yCAGqC;AACzC;AACA;;;GAGG;AACH;IACI,qCAAqC;AACzC;AACA;IACI,8BAA8B;AAClC","sourcesContent":[".fc-toolbar-title {\n    font-size: 18px !important;\n    font-weight: 500;\n}\n.fc-button-primary {\n    font-size: 12px !important;\n    text-transform: capitalize !important;\n    /* border: 1px solid var(--secondary-color) !important;\n    color: var(--secondary-color) !important;\n    outline: 0 !important;\n    background-color: white !important; */\n}\n/* .fc-button-primary.fc-button-active {\n    background: var(--primary-btn-color) !important;\n    color: white !important\n} */\n.fc-today-button {\n    text-transform: capitalize !important;\n}\n.fc .fc-toolbar.fc-header-toolbar {\n    margin-bottom: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
